$font-mdi   : 'Material-Design-Icons';
$mdi-prefix : 'mdi-';

@font-face {
    font-family: "#{$font-mdi}";
    src:url("#{$icons-font-path}#{$font-mdi}.eot?#iefix") format("embedded-opentype"),
        url("#{$icons-font-path}#{$font-mdi}.woff2") format("woff2"),
        url("#{$icons-font-path}#{$font-mdi}.woff") format("woff"),
        url("#{$icons-font-path}#{$font-mdi}.ttf") format("truetype"),
        url("#{$icons-font-path}#{$font-mdi}.svg##{$font-mdi}") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="#{$mdi-prefix}"], [class*=" #{$mdi-prefix}"] {
    font-family: "#{$font-mdi}";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


$mdi-list-icons: (
    'action-3d-rotation': \e600,
    'action-accessibility': \e601,
    'action-account-balance': \e602,
    'action-account-balance-wallet': \e603,
    'action-account-box': \e604,
    'action-account-child': \e605,
    'action-account-circle': \e606,
    'action-add-shopping-cart': \e607,
    'action-alarm': \e608,
    'action-alarm-add': \e609,
    'action-alarm-off': \e60a,
    'action-alarm-on': \e60b,
    'action-android': \e60c,
    'action-announcement': \e60d,
    'action-aspect-ratio': \e60e,
    'action-assessment': \e60f,
    'action-assignment': \e610,
    'action-assignment-ind': \e611,
    'action-assignment-late': \e612,
    'action-assignment-return': \e613,
    'action-assignment-returned': \e614,
    'action-assignment-turned-in': \e615,
    'action-autorenew': \e616,
    'action-backup': \e617,
    'action-book': \e618,
    'action-bookmark': \e619,
    'action-bookmark-outline': \e61a,
    'action-bug-report': \e61b,
    'action-cached': \e61c,
    'action-class': \e61d,
    'action-credit-card': \e61e,
    'action-dashboard': \e61f,
    'action-delete': \e620,
    'action-description': \e621,
    'action-dns': \e622,
    'action-done': \e623,
    'action-done-all': \e624,
    'action-event': \e625,
    'action-exit-to-app': \e626,
    'action-explore': \e627,
    'action-extension': \e628,
    'action-face-unlock': \e629,
    'action-favorite': \e62a,
    'action-favorite-outline': \e62b,
    'action-find-in-page': \e62c,
    'action-find-replace': \e62d,
    'action-flip-to-back': \e62e,
    'action-flip-to-front': \e62f,
    'action-get-app': \e630,
    'action-grade': \e631,
    'action-group-work': \e632,
    'action-help': \e633,
    'action-highlight-remove': \e634,
    'action-history': \e635,
    'action-home': \e636,
    'action-https': \e637,
    'action-info': \e638,
    'action-info-outline': \e639,
    'action-input': \e63a,
    'action-invert-colors': \e63b,
    'action-label': \e63c,
    'action-label-outline': \e63d,
    'action-language': \e63e,
    'action-launch': \e63f,
    'action-list': \e640,
    'action-lock': \e641,
    'action-lock-open': \e642,
    'action-lock-outline': \e643,
    'action-loyalty': \e644,
    'action-markunread-mailbox': \e645,
    'action-note-add': \e646,
    'action-open-in-browser': \e647,
    'action-open-in-new': \e648,
    'action-open-with': \e649,
    'action-pageview': \e64a,
    'action-payment': \e64b,
    'action-perm-camera-mic': \e64c,
    'action-perm-contact-cal': \e64d,
    'action-perm-data-setting': \e64e,
    'action-perm-device-info': \e64f,
    'action-perm-identity': \e650,
    'action-perm-media': \e651,
    'action-perm-phone-msg': \e652,
    'action-perm-scan-wifi': \e653,
    'action-picture-in-picture': \e654,
    'action-polymer': \e655,
    'action-print': \e656,
    'action-query-builder': \e657,
    'action-question-answer': \e658,
    'action-receipt': \e659,
    'action-redeem': \e65a,
    'action-report-problem': \e65b,
    'action-restore': \e65c,
    'action-room': \e65d,
    'action-schedule': \e65e,
    'action-search': \e65f,
    'action-settings': \e660,
    'action-settings-applications': \e661,
    'action-settings-backup-restore': \e662,
    'action-settings-bluetooth': \e663,
    'action-settings-cell': \e664,
    'action-settings-display': \e665,
    'action-settings-ethernet': \e666,
    'action-settings-input-antenna': \e667,
    'action-settings-input-component': \e668,
    'action-settings-input-composite': \e669,
    'action-settings-input-hdmi': \e66a,
    'action-settings-input-svideo': \e66b,
    'action-settings-overscan': \e66c,
    'action-settings-phone': \e66d,
    'action-settings-power': \e66e,
    'action-settings-remote': \e66f,
    'action-settings-voice': \e670,
    'action-shop': \e671,
    'action-shopping-basket': \e672,
    'action-shopping-cart': \e673,
    'action-shop-two': \e674,
    'action-speaker-notes': \e675,
    'action-spellcheck': \e676,
    'action-star-rate': \e677,
    'action-stars': \e678,
    'action-store': \e679,
    'action-subject': \e67a,
    'action-swap-horiz': \e67b,
    'action-swap-vert': \e67c,
    'action-swap-vert-circle': \e67d,
    'action-system-update-tv': \e67e,
    'action-tab': \e67f,
    'action-tab-unselected': \e680,
    'action-theaters': \e681,
    'action-thumb-down': \e682,
    'action-thumbs-up-down': \e683,
    'action-thumb-up': \e684,
    'action-toc': \e685,
    'action-today': \e686,
    'action-track-changes': \e687,
    'action-translate': \e688,
    'action-trending-down': \e689,
    'action-trending-neutral': \e68a,
    'action-trending-up': \e68b,
    'action-turned-in': \e68c,
    'action-turned-in-not': \e68d,
    'action-verified-user': \e68e,
    'action-view-agenda': \e68f,
    'action-view-array': \e690,
    'action-view-carousel': \e691,
    'action-view-column': \e692,
    'action-view-day': \e693,
    'action-view-headline': \e694,
    'action-view-list': \e695,
    'action-view-module': \e696,
    'action-view-quilt': \e697,
    'action-view-stream': \e698,
    'action-view-week': \e699,
    'action-visibility': \e69a,
    'action-visibility-off': \e69b,
    'action-wallet-giftcard': \e69c,
    'action-wallet-membership': \e69d,
    'action-wallet-travel': \e69e,
    'action-work': \e69f,
    'alert-error': \e6a0,
    'alert-warning': \e6a1,
    'av-album': \e6a2,
    'av-timer': \e6a3,
    'av-closed-caption': \e6a4,
    'av-equalizer': \e6a5,
    'av-explicit': \e6a6,
    'av-fast-forward': \e6a7,
    'av-fast-rewind': \e6a8,
    'av-games': \e6a9,
    'av-hearing': \e6aa,
    'av-high-quality': \e6ab,
    'av-loop': \e6ac,
    'av-mic': \e6ad,
    'av-mic-none': \e6ae,
    'av-mic-off': \e6af,
    'av-movie': \e6b0,
    'av-my-library-add': \e6b1,
    'av-my-library-books': \e6b2,
    'av-my-library-music': \e6b3,
    'av-new-releases': \e6b4,
    'av-not-interested': \e6b5,
    'av-pause': \e6b6,
    'av-pause-circle-fill': \e6b7,
    'av-pause-circle-outline': \e6b8,
    'av-play-arrow': \e6b9,
    'av-play-circle-fill': \e6ba,
    'av-play-circle-outline': \e6bb,
    'av-playlist-add': \e6bc,
    'av-play-shopping-bag': \e6bd,
    'av-queue': \e6be,
    'av-queue-music': \e6bf,
    'av-radio': \e6c0,
    'av-recent-actors': \e6c1,
    'av-repeat': \e6c2,
    'av-repeat-one': \e6c3,
    'av-replay': \e6c4,
    'av-shuffle': \e6c5,
    'av-skip-next': \e6c6,
    'av-skip-previous': \e6c7,
    'av-snooze': \e6c8,
    'av-stop': \e6c9,
    'av-subtitles': \e6ca,
    'av-surround-sound': \e6cb,
    'av-videocam': \e6cc,
    'av-videocam-off': \e6cd,
    'av-video-collection': \e6ce,
    'av-volume-down': \e6cf,
    'av-volume-mute': \e6d0,
    'av-volume-off': \e6d1,
    'av-volume-up': \e6d2,
    'av-web': \e6d3,
    'communication-business': \e6d4,
    'communication-call': \e6d5,
    'communication-call-end': \e6d6,
    'communication-call-made': \e6d7,
    'communication-call-merge': \e6d8,
    'communication-call-missed': \e6d9,
    'communication-call-received': \e6da,
    'communication-call-split': \e6db,
    'communication-chat': \e6dc,
    'communication-clear-all': \e6dd,
    'communication-comment': \e6de,
    'communication-contacts': \e6df,
    'communication-dialer-sip': \e6e0,
    'communication-dialpad': \e6e1,
    'communication-dnd-on': \e6e2,
    'communication-email': \e6e3,
    'communication-forum': \e6e4,
    'communication-import-export': \e6e5,
    'communication-invert-colors-off': \e6e6,
    'communication-invert-colors-on': \e6e7,
    'communication-live-help': \e6e8,
    'communication-location-off': \e6e9,
    'communication-location-on': \e6ea,
    'communication-message': \e6eb,
    'communication-messenger': \e6ec,
    'communication-no-sim': \e6ed,
    'communication-phone': \e6ee,
    'communication-portable-wifi-off': \e6ef,
    'communication-quick-contacts-dialer': \e6f0,
    'communication-quick-contacts-mail': \e6f1,
    'communication-ring-volume': \e6f2,
    'communication-stay-current-landscape': \e6f3,
    'communication-stay-current-portrait': \e6f4,
    'communication-stay-primary-landscape': \e6f5,
    'communication-stay-primary-portrait': \e6f6,
    'communication-swap-calls': \e6f7,
    'communication-textsms': \e6f8,
    'communication-voicemail': \e6f9,
    'communication-vpn-key': \e6fa,
    'content-add': \e6fb,
    'content-add-box': \e6fc,
    'content-add-circle': \e6fd,
    'content-add-circle-outline': \e6fe,
    'content-archive': \e6ff,
    'content-backspace': \e700,
    'content-block': \e701,
    'content-clear': \e702,
    'content-content-copy': \e703,
    'content-content-cut': \e704,
    'content-content-paste': \e705,
    'content-create': \e706,
    'content-drafts': \e707,
    'content-filter-list': \e708,
    'content-flag': \e709,
    'content-forward': \e70a,
    'content-gesture': \e70b,
    'content-inbox': \e70c,
    'content-link': \e70d,
    'content-mail': \e70e,
    'content-markunread': \e70f,
    'content-redo': \e710,
    'content-remove': \e711,
    'content-remove-circle': \e712,
    'content-remove-circle-outline': \e713,
    'content-reply': \e714,
    'content-reply-all': \e715,
    'content-report': \e716,
    'content-save': \e717,
    'content-select-all': \e718,
    'content-send': \e719,
    'content-sort': \e71a,
    'content-text-format': \e71b,
    'content-undo': \e71c,
    'device-access-alarm': \e71d,
    'device-access-alarms': \e71e,
    'device-access-time': \e71f,
    'device-add-alarm': \e720,
    'device-airplanemode-off': \e721,
    'device-airplanemode-on': \e722,
    'device-battery-20': \e723,
    'device-battery-30': \e724,
    'device-battery-50': \e725,
    'device-battery-60': \e726,
    'device-battery-80': \e727,
    'device-battery-90': \e728,
    'device-battery-alert': \e729,
    'device-battery-charging-20': \e72a,
    'device-battery-charging-30': \e72b,
    'device-battery-charging-50': \e72c,
    'device-battery-charging-60': \e72d,
    'device-battery-charging-80': \e72e,
    'device-battery-charging-90': \e72f,
    'device-battery-charging-full': \e730,
    'device-battery-full': \e731,
    'device-battery-std': \e732,
    'device-battery-unknown': \e733,
    'device-bluetooth': \e734,
    'device-bluetooth-connected': \e735,
    'device-bluetooth-disabled': \e736,
    'device-bluetooth-searching': \e737,
    'device-brightness-auto': \e738,
    'device-brightness-high': \e739,
    'device-brightness-low': \e73a,
    'device-brightness-medium': \e73b,
    'device-data-usage': \e73c,
    'device-developer-mode': \e73d,
    'device-devices': \e73e,
    'device-dvr': \e73f,
    'device-gps-fixed': \e740,
    'device-gps-not-fixed': \e741,
    'device-gps-off': \e742,
    'device-location-disabled': \e743,
    'device-location-searching': \e744,
    'device-multitrack-audio': \e745,
    'device-network-cell': \e746,
    'device-network-wifi': \e747,
    'device-nfc': \e748,
    'device-now-wallpaper': \e749,
    'device-now-widgets': \e74a,
    'device-screen-lock-landscape': \e74b,
    'device-screen-lock-portrait': \e74c,
    'device-screen-lock-rotation': \e74d,
    'device-screen-rotation': \e74e,
    'device-sd-storage': \e74f,
    'device-settings-system-daydream': \e750,
    'device-signal-cellular-0-bar': \e751,
    'device-signal-cellular-1-bar': \e752,
    'device-signal-cellular-2-bar': \e753,
    'device-signal-cellular-3-bar': \e754,
    'device-signal-cellular-4-bar': \e755,
    'device-signal-cellular-connected-no-internet-0-bar': \e756,
    'device-signal-cellular-connected-no-internet-1-bar': \e757,
    'device-signal-cellular-connected-no-internet-2-bar': \e758,
    'device-signal-cellular-connected-no-internet-3-bar': \e759,
    'device-signal-cellular-connected-no-internet-4-bar': \e75a,
    'device-signal-cellular-no-sim': \e75b,
    'device-signal-cellular-null': \e75c,
    'device-signal-cellular-off': \e75d,
    'device-signal-wifi-0-bar': \e75e,
    'device-signal-wifi-1-bar': \e75f,
    'device-signal-wifi-2-bar': \e760,
    'device-signal-wifi-3-bar': \e761,
    'device-signal-wifi-4-bar': \e762,
    'device-signal-wifi-off': \e763,
    'device-storage': \e764,
    'device-usb': \e765,
    'device-wifi-lock': \e766,
    'device-wifi-tethering': \e767,
    'editor-attach-file': \e768,
    'editor-attach-money': \e769,
    'editor-border-all': \e76a,
    'editor-border-bottom': \e76b,
    'editor-border-clear': \e76c,
    'editor-border-color': \e76d,
    'editor-border-horizontal': \e76e,
    'editor-border-inner': \e76f,
    'editor-border-left': \e770,
    'editor-border-outer': \e771,
    'editor-border-right': \e772,
    'editor-border-style': \e773,
    'editor-border-top': \e774,
    'editor-border-vertical': \e775,
    'editor-format-align-center': \e776,
    'editor-format-align-justify': \e777,
    'editor-format-align-left': \e778,
    'editor-format-align-right': \e779,
    'editor-format-bold': \e77a,
    'editor-format-clear': \e77b,
    'editor-format-color-fill': \e77c,
    'editor-format-color-reset': \e77d,
    'editor-format-color-text': \e77e,
    'editor-format-indent-decrease': \e77f,
    'editor-format-indent-increase': \e780,
    'editor-format-italic': \e781,
    'editor-format-line-spacing': \e782,
    'editor-format-list-bulleted': \e783,
    'editor-format-list-numbered': \e784,
    'editor-format-paint': \e785,
    'editor-format-quote': \e786,
    'editor-format-size': \e787,
    'editor-format-strikethrough': \e788,
    'editor-functions': \e789,
    'editor-format-textdirection-l-to-r': \e78a,
    'editor-format-underline': \e78b,
    'editor-format-textdirection-r-to-l': \e78c,
    'editor-insert-chart': \e78d,
    'editor-insert-comment': \e78e,
    'editor-insert-drive-file': \e78f,
    'editor-insert-emoticon': \e790,
    'editor-insert-invitation': \e791,
    'editor-insert-link': \e792,
    'editor-insert-photo': \e793,
    'editor-merge-type': \e794,
    'editor-mode-comment': \e795,
    'editor-mode-edit': \e796,
    'editor-publish': \e797,
    'editor-vertical-align-bottom': \e798,
    'editor-vertical-align-center': \e799,
    'editor-vertical-align-top': \e79a,
    'editor-wrap-text': \e79b,
    'file-attachment': \e79c,
    'file-cloud': \e79d,
    'file-cloud-circle': \e79e,
    'file-cloud-done': \e79f,
    'file-cloud-download': \e7a0,
    'file-cloud-off': \e7a1,
    'file-cloud-queue': \e7a2,
    'file-cloud-upload': \e7a3,
    'file-file-download': \e7a4,
    'file-file-upload': \e7a5,
    'file-folder': \e7a6,
    'file-folder-open': \e7a7,
    'file-folder-shared': \e7a8,
    'hardware-cast': \e7a9,
    'hardware-cast-connected': \e7aa,
    'hardware-computer': \e7ab,
    'hardware-desktop-mac': \e7ac,
    'hardware-desktop-windows': \e7ad,
    'hardware-dock': \e7ae,
    'hardware-gamepad': \e7af,
    'hardware-headset': \e7b0,
    'hardware-headset-mic': \e7b1,
    'hardware-keyboard': \e7b2,
    'hardware-keyboard-alt': \e7b3,
    'hardware-keyboard-arrow-down': \e7b4,
    'hardware-keyboard-arrow-left': \e7b5,
    'hardware-keyboard-arrow-right': \e7b6,
    'hardware-keyboard-arrow-up': \e7b7,
    'hardware-keyboard-backspace': \e7b8,
    'hardware-keyboard-capslock': \e7b9,
    'hardware-keyboard-control': \e7ba,
    'hardware-keyboard-hide': \e7bb,
    'hardware-keyboard-return': \e7bc,
    'hardware-keyboard-tab': \e7bd,
    'hardware-keyboard-voice': \e7be,
    'hardware-laptop': \e7bf,
    'hardware-laptop-chromebook': \e7c0,
    'hardware-laptop-mac': \e7c1,
    'hardware-laptop-windows': \e7c2,
    'hardware-memory': \e7c3,
    'hardware-mouse': \e7c4,
    'hardware-phone-android': \e7c5,
    'hardware-phone-iphone': \e7c6,
    'hardware-phonelink': \e7c7,
    'hardware-phonelink-off': \e7c8,
    'hardware-security': \e7c9,
    'hardware-sim-card': \e7ca,
    'hardware-smartphone': \e7cb,
    'hardware-speaker': \e7cc,
    'hardware-tablet': \e7cd,
    'hardware-tablet-android': \e7ce,
    'hardware-tablet-mac': \e7cf,
    'hardware-tv': \e7d0,
    'hardware-watch': \e7d1,
    'image-add-to-photos': \e7d2,
    'image-adjust': \e7d3,
    'image-assistant-photo': \e7d4,
    'image-audiotrack': \e7d5,
    'image-blur-circular': \e7d6,
    'image-blur-linear': \e7d7,
    'image-blur-off': \e7d8,
    'image-blur-on': \e7d9,
    'image-brightness-1': \e7da,
    'image-brightness-2': \e7db,
    'image-brightness-3': \e7dc,
    'image-brightness-4': \e7dd,
    'image-brightness-5': \e7de,
    'image-brightness-6': \e7df,
    'image-brightness-7': \e7e0,
    'image-brush': \e7e1,
    'image-camera': \e7e2,
    'image-camera-alt': \e7e3,
    'image-camera-front': \e7e4,
    'image-camera-rear': \e7e5,
    'image-camera-roll': \e7e6,
    'image-center-focus-strong': \e7e7,
    'image-center-focus-weak': \e7e8,
    'image-collections': \e7e9,
    'image-colorize': \e7ea,
    'image-color-lens': \e7eb,
    'image-compare': \e7ec,
    'image-control-point': \e7ed,
    'image-control-point-duplicate': \e7ee,
    'image-crop': \e7ef,
    'image-crop-3-2': \e7f0,
    'image-crop-5-4': \e7f1,
    'image-crop-7-5': \e7f2,
    'image-crop-16-9': \e7f3,
    'image-crop-din': \e7f4,
    'image-crop-free': \e7f5,
    'image-crop-landscape': \e7f6,
    'image-crop-original': \e7f7,
    'image-crop-portrait': \e7f8,
    'image-crop-square': \e7f9,
    'image-dehaze': \e7fa,
    'image-details': \e7fb,
    'image-edit': \e7fc,
    'image-exposure': \e7fd,
    'image-exposure-minus-1': \e7fe,
    'image-exposure-minus-2': \e7ff,
    'image-exposure-plus-1': \e800,
    'image-exposure-plus-2': \e801,
    'image-exposure-zero': \e802,
    'image-filter': \e803,
    'image-filter-1': \e804,
    'image-filter-2': \e805,
    'image-filter-3': \e806,
    'image-filter-4': \e807,
    'image-filter-5': \e808,
    'image-filter-6': \e809,
    'image-filter-7': \e80a,
    'image-filter-8': \e80b,
    'image-filter-9': \e80c,
    'image-filter-9-plus': \e80d,
    'image-filter-b-and-w': \e80e,
    'image-filter-center-focus': \e80f,
    'image-filter-drama': \e810,
    'image-filter-frames': \e811,
    'image-filter-hdr': \e812,
    'image-filter-none': \e813,
    'image-filter-tilt-shift': \e814,
    'image-filter-vintage': \e815,
    'image-flare': \e816,
    'image-flash-auto': \e817,
    'image-flash-off': \e818,
    'image-flash-on': \e819,
    'image-flip': \e81a,
    'image-gradient': \e81b,
    'image-grain': \e81c,
    'image-grid-off': \e81d,
    'image-grid-on': \e81e,
    'image-hdr-off': \e81f,
    'image-hdr-on': \e820,
    'image-hdr-strong': \e821,
    'image-hdr-weak': \e822,
    'image-healing': \e823,
    'image-image': \e824,
    'image-image-aspect-ratio': \e825,
    'image-iso': \e826,
    'image-landscape': \e827,
    'image-leak-add': \e828,
    'image-leak-remove': \e829,
    'image-lens': \e82a,
    'image-looks': \e82b,
    'image-looks-3': \e82c,
    'image-looks-4': \e82d,
    'image-looks-5': \e82e,
    'image-looks-6': \e82f,
    'image-looks-one': \e830,
    'image-looks-two': \e831,
    'image-loupe': \e832,
    'image-movie-creation': \e833,
    'image-nature': \e834,
    'image-nature-people': \e835,
    'image-navigate-before': \e836,
    'image-navigate-next': \e837,
    'image-palette': \e838,
    'image-panorama': \e839,
    'image-panorama-fisheye': \e83a,
    'image-panorama-horizontal': \e83b,
    'image-panorama-vertical': \e83c,
    'image-panorama-wide-angle': \e83d,
    'image-photo': \e83e,
    'image-photo-album': \e83f,
    'image-photo-camera': \e840,
    'image-photo-library': \e841,
    'image-portrait': \e842,
    'image-remove-red-eye': \e843,
    'image-rotate-left': \e844,
    'image-rotate-right': \e845,
    'image-slideshow': \e846,
    'image-straighten': \e847,
    'image-style': \e848,
    'image-switch-camera': \e849,
    'image-switch-video': \e84a,
    'image-tag-faces': \e84b,
    'image-texture': \e84c,
    'image-timelapse': \e84d,
    'image-timer': \e84e,
    'image-timer-3': \e84f,
    'image-timer-10': \e850,
    'image-timer-auto': \e851,
    'image-timer-off': \e852,
    'image-tonality': \e853,
    'image-transform': \e854,
    'image-tune': \e855,
    'image-wb-auto': \e856,
    'image-wb-cloudy': \e857,
    'image-wb-incandescent': \e858,
    'image-wb-irradescent': \e859,
    'image-wb-sunny': \e85a,
    'maps-beenhere': \e85b,
    'maps-directions': \e85c,
    'maps-directions-bike': \e85d,
    'maps-directions-bus': \e85e,
    'maps-directions-car': \e85f,
    'maps-directions-ferry': \e860,
    'maps-directions-subway': \e861,
    'maps-directions-train': \e862,
    'maps-directions-transit': \e863,
    'maps-directions-walk': \e864,
    'maps-flight': \e865,
    'maps-hotel': \e866,
    'maps-layers': \e867,
    'maps-layers-clear': \e868,
    'maps-local-airport': \e869,
    'maps-local-atm': \e86a,
    'maps-local-attraction': \e86b,
    'maps-local-bar': \e86c,
    'maps-local-cafe': \e86d,
    'maps-local-car-wash': \e86e,
    'maps-local-convenience-store': \e86f,
    'maps-local-drink': \e870,
    'maps-local-florist': \e871,
    'maps-local-gas-station': \e872,
    'maps-local-grocery-store': \e873,
    'maps-local-hospital': \e874,
    'maps-local-hotel': \e875,
    'maps-local-laundry-service': \e876,
    'maps-local-library': \e877,
    'maps-local-mall': \e878,
    'maps-local-movies': \e879,
    'maps-local-offer': \e87a,
    'maps-local-parking': \e87b,
    'maps-local-pharmacy': \e87c,
    'maps-local-phone': \e87d,
    'maps-local-pizza': \e87e,
    'maps-local-play': \e87f,
    'maps-local-post-office': \e880,
    'maps-local-print-shop': \e881,
    'maps-local-restaurant': \e882,
    'maps-local-see': \e883,
    'maps-local-shipping': \e884,
    'maps-local-taxi': \e885,
    'maps-location-history': \e886,
    'maps-map': \e887,
    'maps-my-location': \e888,
    'maps-navigation': \e889,
    'maps-pin-drop': \e88a,
    'maps-place': \e88b,
    'maps-rate-review': \e88c,
    'maps-restaurant-menu': \e88d,
    'maps-satellite': \e88e,
    'maps-store-mall-directory': \e88f,
    'maps-terrain': \e890,
    'maps-traffic': \e891,
    'navigation-apps': \e892,
    'navigation-arrow-back': \e893,
    'navigation-arrow-drop-down': \e894,
    'navigation-arrow-drop-down-circle': \e895,
    'navigation-arrow-drop-up': \e896,
    'navigation-arrow-forward': \e897,
    'navigation-cancel': \e898,
    'navigation-check': \e899,
    'navigation-chevron-left': \e89a,
    'navigation-chevron-right': \e89b,
    'navigation-close': \e89c,
    'navigation-expand-less': \e89d,
    'navigation-expand-more': \e89e,
    'navigation-fullscreen': \e89f,
    'navigation-fullscreen-exit': \e8a0,
    'navigation-menu': \e8a1,
    'navigation-more-horiz': \e8a2,
    'navigation-more-vert': \e8a3,
    'navigation-refresh': \e8a4,
    'navigation-unfold-less': \e8a5,
    'navigation-unfold-more': \e8a6,
    'notification-adb': \e8a7,
    'notification-bluetooth-audio': \e8a8,
    'notification-disc-full': \e8a9,
    'notification-dnd-forwardslash': \e8aa,
    'notification-do-not-disturb': \e8ab,
    'notification-drive-eta': \e8ac,
    'notification-event-available': \e8ad,
    'notification-event-busy': \e8ae,
    'notification-event-note': \e8af,
    'notification-folder-special': \e8b0,
    'notification-mms': \e8b1,
    'notification-more': \e8b2,
    'notification-network-locked': \e8b3,
    'notification-phone-bluetooth-speaker': \e8b4,
    'notification-phone-forwarded': \e8b5,
    'notification-phone-in-talk': \e8b6,
    'notification-phone-locked': \e8b7,
    'notification-phone-missed': \e8b8,
    'notification-phone-paused': \e8b9,
    'notification-play-download': \e8ba,
    'notification-play-install': \e8bb,
    'notification-sd-card': \e8bc,
    'notification-sim-card-alert': \e8bd,
    'notification-sms': \e8be,
    'notification-sms-failed': \e8bf,
    'notification-sync': \e8c0,
    'notification-sync-disabled': \e8c1,
    'notification-sync-problem': \e8c2,
    'notification-system-update': \e8c3,
    'notification-tap-and-play': \e8c4,
    'notification-time-to-leave': \e8c5,
    'notification-vibration': \e8c6,
    'notification-voice-chat': \e8c7,
    'notification-vpn-lock': \e8c8,
    'social-cake': \e8c9,
    'social-domain': \e8ca,
    'social-group': \e8cb,
    'social-group-add': \e8cc,
    'social-location-city': \e8cd,
    'social-mood': \e8ce,
    'social-notifications': \e8cf,
    'social-notifications-none': \e8d0,
    'social-notifications-off': \e8d1,
    'social-notifications-on': \e8d2,
    'social-notifications-paused': \e8d3,
    'social-pages': \e8d4,
    'social-party-mode': \e8d5,
    'social-people': \e8d6,
    'social-people-outline': \e8d7,
    'social-person': \e8d8,
    'social-person-add': \e8d9,
    'social-person-outline': \e8da,
    'social-plus-one': \e8db,
    'social-poll': \e8dc,
    'social-public': \e8dd,
    'social-school': \e8de,
    'social-share': \e8df,
    'social-whatshot': \e8e0,
    'toggle-check-box': \e8e1,
    'toggle-check-box-outline-blank': \e8e2,
    'toggle-radio-button-off': \e8e3,
    'toggle-radio-button-on': \e8e4
);

// remove after bug fix sass 3.3.x and add quote in $mdi-list-icons: ( 'foo': \exxx )
@function unicode($str){
  @return "'" + $str + "'";
}

@each $mdi-icon-name, $mdi-icon-value in $mdi-list-icons {
  .#{$mdi-prefix}#{$mdi-icon-name}:before {
    content: unicode($mdi-icon-value);
  }
}