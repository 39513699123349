nav {
  color: #fff;
  @extend .z-depth-1;
  background-color: $primary-color;
  width: 100%;
  height: $navbar-height-mobile;
  line-height: $navbar-height-mobile;

  a { color: #fff; }

  .nav-wrapper {
    position: relative;
    height: 100%;

    i {
      display: block;
      font-size: 2rem;
    }
  }

  @media #{$large-and-up} {
    a.button-collapse { display: none; }
  }


  // Collapse button
  .button-collapse {
    float: left;
    position: relative;
    z-index: 1;
    height: $navbar-height-mobile;

    i {
      font-size: 2.7rem;
      height: $navbar-height-mobile;
      line-height: $navbar-height-mobile;
    }
  }


  // Logo
  .brand-logo {
    position: absolute;
    color: #fff;
    display: inline-block;
    font-size: 2.1rem;
    padding: 0;

    &.center {
      left: 50%;
      @include transform(translateX(-50%));
    }

    @media #{$medium-and-down} {
      left: 50%;
      @include transform(translateX(-50%));
    }

    &.right {
      right: 0.5rem;
      padding: 0;
    }
  }


  // Navbar Links
  ul {
    margin: 0;

    li {
      @include transition(background-color .3s);
      float: left;
      padding: 0;

      &:hover, &.active {
        background-color: rgba(0,0,0,.1);
      }
    }
    a {
      font-size: 1rem;
      color: #fff;
      display: block;
      padding: 0 15px;
    }

    &.left {
      float: left;
    }
  }

  // Navbar Search Form
  .input-field {
    margin: 0;

    input {
      height: 100%;
      font-size: 1.2rem;
      border: none;
      padding-left: 2rem;

      &:focus, &[type=text]:valid, &[type=password]:valid,
      &[type=email]:valid, &[type=url]:valid, &[type=date]:valid {
        border: none;
        box-shadow: none;
      }
    }
    label {
      top: 0;
      left: 0;

      i {
        color: rgba(255,255,255,.7);
        @include transition(color .3s);
      }
      &.active i { color: #fff; }
      &.active {
        @include transform(translateY(0));
      }
    }

  }

}

// Fixed Navbar
.navbar-fixed {
  position: relative;
  height: $navbar-height-mobile;
  z-index: 998;

  nav {
    position: fixed;
  }
}
@media #{$medium-and-up} {
  nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
    height: $navbar-height;
    line-height: $navbar-height;
  }
  .navbar-fixed {
    height: $navbar-height;
  }
}
